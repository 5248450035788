








































































































































import { debounce } from "lodash";
import { defineComponent, reactive, watch } from "@vue/composition-api";
import { isKonsulen, isResiden } from "@/apps/accounts/modules/store";
import KaryaIlmiahFM from "../models/karyaIlmiahForm";
import { SaveContext } from "@/apps/core/models/formModel";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "KaryaIlmiahListBox",
  props: {
    karyaIlmiah: { type: Object, required: true },
    verifikasi: String,
  },
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    LulusTag: () => import("@/apps/core/components/LulusTag.vue"),
  },
  setup(props) {
    // karyaIlmiah required dan initial value-nya adalah []
    const PICS_LIMIT = 3;
    const pengujiCount = props.karyaIlmiah.pengujiSet.length;
    const pembimbingCount = props.karyaIlmiah.pembimbingSet.length;
    const pengujiArr = props.karyaIlmiah.pengujiSet.slice(0, PICS_LIMIT);
    const pembimbingArr = props.karyaIlmiah.pembimbingSet.slice(0, PICS_LIMIT);

    const form = new KaryaIlmiahFM(["hasil"], ["nilai"]);
    const formRef = reactive(form);
    const instance = formRef.instance;

    Object.assign(formRef.instance, props.karyaIlmiah);
    Object.assign(formRef.initState, form.instance);
    watch(instance, () => {
      if (!formRef.validity.edited) {
        formRef.setEdited(true);
      }
    });

    const updateNilai = debounce((value) => {
      formRef.validate(value);
      if (formRef.getIsValid().value) formRef.save(SaveContext.Update, false);
    }, 1000);
    formRef.resetErrorMap();

    return {
      // Data
      detailUrl: "/karyailmiah",
      // menuConfigs,
      pembimbingCount,
      pembimbingArr,
      pengujiCount,
      pengujiArr,
      PICS_LIMIT,
      errorMap: formRef.errorMap, // reactive
      instance,

      // Computed
      isKonsulen,
      isResiden,

      // Method
      toUserDateFormat,
      updateNilai,
    };
  },
});
